import React from 'react';
import Experience from './experience';
import Breadcrumb from './breadcrumb';

const CurriculumTemplate = ({
  data: { title, subtitle, experiences, formationSubtitle, formationExperiences }
}) => {
  const breadcrumb = [
    {
      link: '/chi-sono/',
      name: 'Chi sono'
    },
    {
      name: title
    }
  ];
  return (
    <>
      <div className="section section--dark mt-0">
        <div className="container-xl">
          <div className="row mb-3">
            <div className="col-12">
              <Breadcrumb breadcrumb={breadcrumb} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h1 className="my-3">{title}</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="section resume">
        <div className="container-xl">
          <div className="row mb-4">
            <div className="col">
              <h2>{subtitle}</h2>
            </div>
          </div>
          {experiences &&
            experiences.map((item, i) => (
              <Experience key={item.title} id={item.id || `experience-${i}`} {...item} />
            ))}
        </div>
      </div>
      <div className="section resume">
        <div className="container-xl">
          {formationSubtitle && (
            <div className="row mb-4">
              <div className="col">
                <h2>{formationSubtitle}</h2>
              </div>
            </div>
          )}
          {formationExperiences &&
            formationExperiences.map((item, i) => (
              <Experience key={item.title} id={item.id || `formation-${i}`} {...item} />
            ))}
        </div>
      </div>
    </>
  );
};

export default CurriculumTemplate;
