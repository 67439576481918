import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import TemplateCurriculum from '../../components/template-curriculum';

const ResumePage = ({ data }) => {
  const { page } = data;
  return (
    <Layout data={data}>
      <Seo title={page.tagTitle || page.title} description={page.metaDescription} data={data} />
      <TemplateCurriculum data={page} />
    </Layout>
  );
};

export default ResumePage;
export const query = graphql`
  query CurriculumQuery {
    ...HeaderFragment
    ...SeoFragment
    page: curriculumPageJson {
      title
      tagTitle
      metaDescription
      subtitle
      experiences {
        id
        title
        subtitle
        time
        description
        activities
      }
      formationSubtitle
      formationExperiences {
        id
        title
        subtitle
        time
        description
        activities
      }
    }
  }
`;
