import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import idify from '../utils/idify';

const Experience = ({
  id,
  title,
  subtitle,
  info,
  time,
  activities,
  description,
  startOpen = false
}) => {
  const [open, setOpen] = useState(startOpen);
  const toggleSection = useCallback(() => setOpen((value) => !value), []);

  return (
    <div>
      <span id={idify(title)} className="section__anchor"></span>
      <div className="row">
        <div className="col-12 col-md-5">
          <h3 id={`title-${id}`}>{title}</h3>
          {subtitle && <div dangerouslySetInnerHTML={{ __html: subtitle }}></div>}
        </div>
        {time && (
          <div className="col-12 col-md-4 mt-2 mt-md-0">
            <span>{time}</span>
            {info && <div className="mt-2 mb-4" dangerouslySetInnerHTML={{ __html: info }}></div>}
          </div>
        )}
        {(activities || description) && (
          <div
            className={classNames('col-12 col-md-3 text-center mt-2 mt-md-0', {
              'offset-md-4': !time
            })}
          >
            <button
              className={classNames('toggle', { open: open })}
              title={`${open ? 'Nascondi' : 'Mostra'} dettagli`}
              aria-label={`${open ? 'Nascondi' : 'Mostra'} dettagli`}
              aria-expanded={open ? 'true' : 'false'}
              aria-controls={id}
              onClick={toggleSection}
            ></button>
          </div>
        )}
      </div>
      {(activities || description) && (
        <div className="row mb-5">
          <div className="col-12">
            <div
              id={id}
              role="region"
              aria-labelledby={`title-${id}`}
              className={classNames('accordion', { open: open })}
            >
              {activities && (
                <ul className="mt-3">
                  {activities.map((activity, i) => (
                    <li key={i}>{activity}</li>
                  ))}
                </ul>
              )}
              {description && <div dangerouslySetInnerHTML={{ __html: description }}></div>}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Experience;
