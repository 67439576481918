import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

const Breadcrumb = ({ breadcrumb, className }) => {
  return (
    <div className={classNames('breadcrumb', className)} role="navigation" aria-label="breadcrumb">
      <ol className="breadcrumb__list" itemType="http://schema.org/BreadcrumbList" itemScope="">
        <li
          className="breadcrumb__item"
          itemType="http://schema.org/ListItem"
          itemScope=""
          itemProp="itemListElement"
        >
          <div aria-level="1" itemProp="item">
            <Link to="/" title="Home" className="breadcrumb__link" itemProp="url">
              <span itemProp="name">Home</span>
            </Link>
          </div>
          <meta content="1" itemProp="position" />
        </li>
        {breadcrumb.map((item, i) => (
          <li
            key={item.link + item.name}
            className="breadcrumb__item"
            itemType="http://schema.org/ListItem"
            itemScope=""
            itemProp="itemListElement"
          >
            <div aria-level={i + 2} itemProp="item">
              {item.link ? (
                <Link to={item.link} title={item.name} className="breadcrumb__link" itemProp="url">
                  <span itemProp="name">{item.name}</span>
                </Link>
              ) : (
                <span
                  itemProp="name"
                  className={classNames('breadcrumb__link', {
                    'breadcrumb__link--active': i === breadcrumb.length - 1
                  })}
                >
                  {item.name}
                </span>
              )}
            </div>
            <meta content={i + 2} itemProp="position" />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Breadcrumb;
